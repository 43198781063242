//Theming colors
$black: #000;
$white: #fff;
$purple: #090467;
$purple-hover: #04004d;
$green: #70ecd4;
$btn-sign-up: #70ecd4;
$btn-sign-up-ld: #2effaf;
$magneto-primary: #e0f3fb;
$magneto-primary-btn: rgba(163, 218, 242, 1);

/* -------lights------ */
$light-grey: #f5f5f5;
$light-grey-hover: #adadad63;
$light-purple: #4618d2;
$gray-1: #c4c4c4;
$gray-2: #d1d1d1;
$gray-3: #e4e4e4;
$gray-4: #f7f7f7;
$gray-5: #f5f5f5;
$gray-6: #2f2f2f;
$gray-7: #cfcfde;
$gray-8: #f4f4fa;
$gray-text: #808080;
$gray-title: #363b3f;
$gray-subtitle: #717171;
$background-btn: #f4f4fa;

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// HD screen / desktop
$screen-hd: 1366px;
$screen-hd-min: $screen-hd;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

// Content Footer
$content-footer-desktop-height: 72px;
$content-footer-mobile-height: 108px;
$footer-link: #333333;

// B2B Colors ------------
$b2b-blue: #00a7e1;
$b2b-grey: rgb(227, 227, 227, 60%);
$b2b-grey-inactive-text: rgba(128, 128, 128, 0.5);
$b2b-blue-dark: #04004d;

//Business colors -----------------
$orange-business: #ed440f;

// font-size -----------------------
$fz-p-global: 110%;

$b2b-fz-btn-banner: 125%;
$b2b-fz-p-banner: 200%;

$b2b-fz-h1-global: 210%;
$b2b-fz-h2-global: 190%;
$b2b-fz-h3-global: 170%;
$b2b-fz-h4-global: 160%;
$b2b-fz-h5-global: 150%;

// -------mobile fonts size ----------
$fz-p-global-mobile: 100%;

$b2b-fz-btn-banner-mobile: 100%;
$b2b-fz-p-banner-mobile: 130%;

$b2b-fz-h1-global-mobile: 150%;
$b2b-fz-h2-global-mobile: 140%;
$b2b-fz-h3-global-mobile: 135%;
$b2b-fz-h4-global-mobile: 135%;
$b2b-fz-h5-global-mobile: 135%;

// box shadow -----------------------
$box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
$box-shadow-light: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);

// transition -----------------------
$transition: 0.4s;
$filter-brightness: brightness(0.7);
$filter-brightness-light: brightness(0.9);

// breakpoints ----------------------
$small: 480px;
$mobile: 600px;
$small-table: 768px;
$large-table: 1100px;

//Landings
$landing-btn-apply: #090467;
